.front-page {
    h2 {
        display: flex;
        flex-direction: column;
        font-size: 3.75rem;
        line-height: 3.75rem;
        font-weight: $font-weight-normal;
        text-transform: uppercase;
        color: $color-dark;
        padding: 0;
        border-bottom: 0;
        margin: 0 0 20px;

        &:after {
            content: '';
            display: inline-block;
            width: 130px;
            height: 8px;
            border-radius: $border-radius;
            margin-top: 10px;
        }
    }
}

//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        margin: auto;
        width: 100%;
        height: 150px;
        background: $color-white;
        clip-path:ellipse(55% 110px at bottom);
        z-index: 3;
        bottom: -40px;
    }

    &__pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 130px;
        z-index: 2;
        width: 100%;
        filter: drop-shadow(0px 0px 10px #000000);

        .swiper-pagination-bullet {
            width: 14px;
            height: 14px;
            margin: 0 8px;
            transition: $duration;
            background-color: $color-white;
            border-radius: 100px;
            opacity: 1;

            &:hover {
                background-color: $color-second;
            }

            &-active {
                width: 27px;
                background-color: $color-second;
            }
        }
    }

    .swiper-container {
        height: 660px;
        width: 100%;
        z-index: 2;

        .swiper-slide {
            img {
                position: absolute;
                object-fit: cover;
                width: 100%;
                height: 100%;
                object-position: top;
            }
        }

        .container--pagination {
            position: absolute;
            right: 0;
            left: 0;
            top: 0;
            height: 100%;
            width: 1290px;
            max-width: 100%;
            pointer-events: none;

            div {
                pointer-events: auto;
                width: 40px;
                height: 40px;
                background: $color-main;
                border-radius: 50px;

                &::after {
                    position: relative;
                    font-size: $font-size--text;
                    font-weight: $font-weight-bold;
                    color: $color-white;
                }
            }
        }
    }

    .slideshow {
        display: inherit;
        position: relative;
        width: 100%;
        height: 100%;

        .container--slideshow {
            position: relative;
            height: 100%;
            z-index: 2;
        }

        &__content {
            position: absolute;
            bottom: 175px;
            text-align: center;
            width: 100%;
        }

        &__title {
            font-family: $font-family--heading;
            font-size: 2.5rem;
            line-height: 2.75rem;
            font-weight: $font-weight-bold;
            text-shadow: 0px 0px 20px $color-black, 0px 0px 10px $color-black;
            color: $color-white;
        }
    }

    .video-container {
        height: 660px;
        width: 100%;

        .slideshow {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;

            video,
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }

            img {
                display: none;
            }
        }
    }
    
    &__gradient {
        position: absolute;
        z-index: 1; 
        height: 100%;
        width: 100%;
        background: linear-gradient(180deg, rgba(18, 18, 19, 0) 33.55%, rgba(18, 18, 19, 0.54) 106.06%);
    }
}

//======================================================================================================
// Accès rapides
//======================================================================================================
.home-access {
    position: relative;
    z-index: 5;
    margin-top: -95px;

    &__list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__link {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;

        &:hover {
            .home-access {
                &__svg-container {
                    box-shadow: 0px 0px 0 rgba(0, 0, 0, 0);

                    &:after {
                        width: 100%;
                        height: 100%;
                    }

                    svg {
                        path[fill="#282828"],
                        path[fill="#01A16D"],
                        path[fill="#0075BA"] {
                            fill: $color-white;
                        }
                    }
                }

                &__label {
                    color: $color-dark;
                }
            }
        }
    }

    &__svg-container {
        position: relative;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        margin-bottom: 10px;
        transition: .3s;

        &:before,
        &:after {
            content: '';
            position: absolute;
            border-radius: 50%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }

        &:before {
            height: 100%;
            width: 100%;
            background-color: $color-white;
            z-index: 0;

        }

        &:after {
            height: 0;
            width: 0;
            background-color: $color-main;
            transition: .4s ease-in-out;
            z-index: 1;

        }

        svg {
            position: relative;
            z-index: 2;

            path {
                transition: .3s;
            }
        }
    }

    &__label {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: $font-weight-black;
        font-size: $font-size--text-small;
        text-align: center;
        text-transform: uppercase;
        width: 130px;
        min-height: 35px;
    }
}

//======================================================================================================
// Actualités
//======================================================================================================
.home-posts {
    position: relative;
    z-index: 5;
    padding-top: 125px;

    &__title {
        &:after {
            background-color: $color-main;
        }
    }

    &__container {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-height: 820px;
    }

    &__shape {
        position: absolute;
        top: -95px;
        left: -170px;
        width: 1200px;
        height: auto;
        z-index: -1;
    }

    .featured-post {
        width: 540px;
        margin-bottom: 40px;
        margin-left: 60px;

        &:hover {
            color: $color-dark;

            .featured-post {
                &__image-container {
                    box-shadow: 0 0 30px rgb(0 0 0 / 20%);
                }

                &__taxonomy {
                    margin-left: 30px;
                    transition: $duration;
                }

                &__read-more {
                    span {
                        border-color: $color-main;
                    }
                }
            }
        }

        &__image-container {
            background: url(/wp-content/themes/noyau/assets/images/icon-post.png) $color-gray;
            background-repeat: no-repeat;
            background-position: center;
            box-shadow: 0px 0px 30px rgb(0 0 0 / 5%);
            width: 570px;
            height: 570px;
            border-radius: 50%;
            overflow: hidden;
            transition: $duration;
            
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__content {
            position: relative;
        }

        &__title {
            margin: 0 0 10px;
            color: $color-dark;
            font-size: 1.625rem;
            line-height: 1.875rem;
        }

        &__taxonomy {
            margin-bottom: 15px;
            margin-top: 15px;
            margin-left: 0;
            transition: .45s;

            &:after {
                content: '';
                position: absolute;
                left: 0;
                height: 6px;
                width: 100%;
                background-color: $color-main;
                border-radius: $border-radius;
                opacity: .6;
                z-index: -1;
            }
        }

        &__read-more {
            position: relative;
            display: inline-flex;
            align-items: center;
            color: $color-main;
            font-size: $font-size--text-xsmall;
            text-transform: uppercase;
            font-weight: $font-weight-extrabold;
            margin-top: 10px;
            padding-right: 10px;

            svg {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: transparent;
                fill: $color-main;
                margin-right: 10px;
                transition: $duration;
            }

            span {
                border-bottom: 2px solid transparent;
                transition: $duration;
            }
        }

        &--0 {
            position: relative;
            width: 570px;
            margin-left: 0;

            .featured-post {
                &__content {
                    position: absolute;
                    width: 430px;
                    top: 395px;
                    left: 0;
                    box-shadow: 0px 0px 1px rgba(27, 35, 54, 0.08), 0px 0px 10px rgba(27, 35, 54, 0.08);
                    background-color: $color-white;
                    border-radius: $border-radius;
                    padding: 35px 30px 30px;
                    box-sizing: border-box;
                }

                &__taxonomy {
                    position: absolute;
                    margin-top: 0;
                    top: -13px;
                    left: 30px;
                }
            }
        }
    }

    &__template {
        display: flex;
        justify-content: flex-end;
    }

    &__template-btn {
        width: 540px;    
        margin: 20px 0 0;
    }
}

//======================================================================================================
// Agenda
//======================================================================================================
.home-events {
    padding: 70px 0 25px;
    position: relative;
    z-index: 2;

    > .container {
        position: relative;
    }

    &__shape {
        position: absolute;
        width: 1200px;
        height: auto;
        top: -50px;
        left: 275px;
        z-index: -1;
    }

    &__title {
        &:after {
            background-color: $color-second;
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
    }

    .featured-event {
        position: relative;
        filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.05));
        border-radius: $border-radius;
        display: flex;
        width: 570px;
        margin-bottom: 40px;

        &:hover {
            .featured-event {
                &__taxonomy {
                    background-color: $color-white;
                    color: $color-second;
                }

                &__content {
                    &:before {
                        width: 100%;
                        height: 100%;
                    }
                }

                &__title {
                    color: $color-white;
                }

                &__dates {
                    p {
                        color: $color-second;
        
                        &:before {
                            background-color: $color-white;
                        }
                    }

                    svg {
                        path {
                            fill: $color-second;
                        }
                    }
                }
            }
        }

        &__image-container {
            position: relative;
            z-index: 2;
            width: 300px;
            height: 285px;
            background: url(/wp-content/themes/noyau/assets/images/icon-events.png) $color-gray;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: $border-radius 0 0 $border-radius;
            overflow: hidden;
            flex: none;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }
        }

        &__content {
            position: relative;
            z-index: 1;
            flex: 1;
            background-color: $color-white;
            border-radius: 0 $border-radius $border-radius 0;
            padding: 20px;
            height: 285px;
            overflow: hidden;

            &:before {
                content: '';
                position: absolute;
                background-color: $color-second;
                border-radius: 0 $border-radius $border-radius 0;
                top: 0;
                left: 0;
                width: 0;
                height: 0;
                transition: .3s ease-in-out;
            }
        }

        &__title {
            position: relative;
            color: $color-dark;
            font-size: 1.625rem;
            margin: 45px 0 0;
            transition: .3s ease-in-out;
        }

        &__dates {
            display: flex;
            align-items: center;

            svg {
                position: relative;
                z-index: 3;
                margin: 0 8px 20px;
                height: auto;
                width: 30px;

                path {
                    transition: .3s ease-in-out;
                }
            }

            p {
                position: relative;
                color: $color-white;
                text-align: center;

                &:before {
                    content: '';
                    position: absolute;
                    height: 155px;
                    width: 155px;
                    top: -45px;
                    left: -45px;
                    background-color: $color-second;
                    border-radius: 50%;
                    z-index: 0;
                    transition: .3s ease-in-out;
                }

                span {
                    position: relative;
                }
            }

            &-first {
                z-index: 2;
            }

            &-last {
                z-index: 1;

                &::before {
                    opacity: .7;
                }
            }
        }

        &__date-day {
            display: block;
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            font-size: 3.125rem;
            line-height: 3rem;
        }

        &__date-month {
            display: block;
            font-size: 1.875rem;
            line-height: 1.875rem;
            font-weight: $font-weight-light;
            text-transform: uppercase;
        }

        &__taxonomy {
            position: absolute;
            z-index: 3;
            top: 15px;
            left: 15px;
            background-color: $color-second;
            color: $color-white;
            text-align: center;
            padding: 6px 8px;
            border-radius: 50px;
            font-size: $font-size--text-xsmall;
            font-weight: $font-weight-extrabold;
            text-transform: uppercase;
            transition: $duration;
            max-width: 275px;
        }

    }

    &__template {
        display: flex;
        justify-content: flex-end;
    }

    &__template-btn {
        width: 370px;
        margin: 0;
        border-color: $color-second;
    }

    &__filters {
        margin: 35px 0 30px;
        display: flex;
        justify-content: space-between;

        &-btn {
            border-color: $color-second;
            background-color: $color-second;
            width: 180px;
            margin: 0;

            &:hover {
                background-color: $color-white;
                color: $color-second;
                border-color: $color-second;
            }
        }
    }

    &__select-wrapper {
        display: flex;
        align-items: center;
        margin-right: 40px;

        .select-box {
            display: flex;
            align-items: center;


            &:first-child {
                margin-right: 40px;
            }
        }

        label {
            margin: 0 10px 0 0;
        }

        input {
            background: $color-white;
            border: 1px solid $color-second!important;
            box-sizing: border-box;
            border-radius: 20px;
            width: 170px;
            height: 38px;
            padding-left: 12px;
            color: $color-dark;
        }

        select {
            appearance: none;
            text-indent: 1px;
            text-overflow: '';
            background: $color-white url(/wp-content/themes/bouaye/assets/src/images/sprite-images/home/ic-chevron-bas.svg) no-repeat 100% center;
            background-position: calc(100% - 10px) center;
            background-size: 23px 18px;
            border: 1px solid $color-second;
            box-sizing: border-box;
            border-radius: 20px;
            width: 370px;
            height: 38px;
            padding: 2px 20px;

            &:focus {
                border: 2px solid $color-second;
            }
        }
    }
}
.home {
    .ui-datepicker-calendar {
        .ui-state-default {
            pointer-events: none;
            cursor: pointer;
        }
    }

    .ui-datepicker td {
        cursor: pointer;
    }

    .ui-datepicker.ui-widget-content table tbody td {
        &:hover {
            a {
                background: $color-main!important;
                color: $color-white!important;
            }
        }
    }
}

//======================================================================================================
// Publications
//======================================================================================================
.home-docs-contacts {
    padding-top: 85px;
    background-color: $color-white;
    position: relative;
    z-index: 1;

    > .container {
        display: flex;
        justify-content: space-between;
    }
}

.home-documents {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        width: 465px;
        height: 465px;
        border-radius: 50%;
        background-color: $color-third;
        top: -85px;
        left: -180px;
        z-index: -1;
    }

    &__container {
        display: flex;
        margin-top: 40px;
    }

    &__title {
        &:after {
            background-color: $color-second;
        }
    }

    .home-document {
        &__container-image {
            width: 250px;
            height: 370px;
            overflow: hidden;
            filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.05));
            margin-right: 40px;
            background: url(/wp-content/themes/noyau/assets/images/icon-documents.png) $color-gray;
            background-repeat: no-repeat;
            background-position: center;
    
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__content {
            max-width: 320px;
        }

        &__title {
            font-size: 1.625rem;
            line-height: 1.875rem;
            color: $color-dark;
            margin: 15px 0 20px;
        }

        &__buttons {
            display: flex;
        }

        &__btn {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-second;
            border: 2px solid $color-second;
            width: 55px;
            height: 55px;
            border-radius: 50%;
            margin: 35px 30px 35px 0;

            &:hover {
                background-color: transparent;
                
                svg {
                    fill: $color-second;
                }
            }

            svg {
                fill: $color-white;
                transition: $duration;
            }
        }

        &__template-btn {
            width: 320px;
            margin: 0;
        }
    }
}
//======================================================================================================
// Annuaire
//======================================================================================================
.home-annuaire {
    width: 485px;

    &__title {
        &:after {
            background-color: $color-main;
        }
    }

    label {
        font-family: $font-family--heading;
        font-weight: $font-weight-bold;
        font-size: 1.375rem;
        line-height: 1.375rem;
        margin: 55px 0 15px;
    }

    &__wrapper-select {
        display: flex;
        align-items: center;
        position: relative;
        height: 85px;
        margin-bottom: 25px;

        select {
            appearance: none;
            text-indent: 1px;
            text-overflow: '';
            background: $color-white url(/wp-content/themes/bouaye/assets/src/images/sprite-images/home/ic-chevron-bas.svg) no-repeat 100% center;
            background-position: calc(100% - 15px) center;
            display: flex;
            align-items: center;
            width: 415px;
            height: 46px;
            color: $color-dark;
            border: 1px solid $color-main;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
            border-radius: 100px;
            padding: 2px 25px;
            position: relative;
            z-index: 1;
            transition: all 0.25s ease-in-out;

            &:focus {
                border: 2px solid $color-main;
            }
        }
    }

    &__btn {
        position: absolute;
        margin: 0;
        width: 85px;
        height: 85px;
        border-radius: 50%;
        padding: 0;
        right: 0;
        font-size: 1.375rem;
    }

    &__template-btn {
        margin: 20px 0 0;
        width: 100%;
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .home-slideshow {
        &:before {
            height: 80px;
            clip-path: ellipse(60% 80px at bottom);
            bottom: -30px;
        }

        .swiper-container {
            height: 600px;
        }

        .video-container {
            height: 600px;
        }
    }

    .home-access {
        margin-top: -75px;
    }

    .home-posts {    
        &__container {
            max-height: 820px;
        }
    
        &__shape {
            top: -95px;
            left: -130px;
            width: 980px;
        }
    
        .featured-post {
            width: 460px;
            margin-left: 20px;
    
            &__image-container {
                width: 460px;
                height: 460px;
            }
    
            &--0 {
                width: 460px;
                margin-left: 0;
            }
        }
    
        &__template-btn {
            width: 460px;    
            margin: 0;
        }
    }

    .home-docs-contacts {
        padding-top: 65px;
        padding-bottom: 20px;
    }

    .home-events {
        &__shape {
            width: 980px;
            top: -10px;
            left: 100px;
        }

        .featured-event {
            width: 460px;
            margin-bottom: 45px;

            &__content {
                padding: 10px 20px 20px 20px;
                height: 280px;
            }

            &__title {
                margin: 30px 0 0;
            }

            &__image-container {
                width: 220px;
                height: 280px;
            }

            &__dates {
                p {
                    margin: 0px 0 0 -5px;

                    &:before {
                        width: 125px;
                        height: 125px;
                        top: -35px;
                        left: -35px;
                    }
                }
            }

            &__date-month {
                font-size: 1.625rem;
                line-height: 1.625rem;
            }

            &__taxonomy {
                max-width: 190px;
            }
        }

        &__select-wrapper {
            margin-right: 20px;

            input {
                width: 110px;
            }

            select {
                width: 315px;
            }
        }

        &__template-btn {
            width: 300px;
        }
    }

    .home-documents {
        &:before {
            width: 350px;
            height: 350px;
            left: -95px;
            top: -65px;
        }

        &__container {
            margin-top: 55px;
        }

        .home-document {
            &__title {
                margin: 0 0 20px;
            }

            &__container-image {
                width: 200px;
                height: 295px;
                margin-right: 20px;
            }

            &__content {
                max-width: 240px;
            }

            &__btn {
                margin: 15px 28px 30px 0;
            }

            &__template-btn {
                width: 240px;
            }
        }
    }

    .home-annuaire {
        width: 400px;

        &__wrapper-select {
            margin-bottom: 20px;

            select {
                width: 330px;
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .home-slideshow {
        position: relative;

        .swiper-container {
            height: 480px;
        }

        .video-container {
            height: 480px;
        }

        &__pagination {
            bottom: 110px;
        }

        .video-container {
            .slideshow {
                video {
                    display: none;
                }
                img {
                    display: block;
                }
            }
        }

        .slideshow {
            &__content {
                bottom: 140px;
            }
        }
    }

    .home-access {
        &__link {
            padding: 0 20px;
        }
    }

    .home-posts {    
        &__container {
            max-height: 100%;
        }

        &__shape {
            width: 915px;
        }
    
        .featured-post {
            margin-left: 0;
            width: 100%;
    
            &__image-container {
                width: 460px;
                height: 460px;
            }
    
            &--0 {
                margin: 10px 0 50px;

                .featured-post {
                    &__content {
                        position: relative;
                        top: 0;
                        margin-top: -65px;
                        width: 100%;
                    }
                }
            }
        }

        &__template {
            margin-top: 10px;
        }

        &__template-btn {
            width: 100%;
        }
    }

    .home-events {
        &__shape {
            width: 915px;
            left: -150px;
            top: 0;
        }

        &__filters {
            flex-wrap: wrap;
        }

        &__select-wrapper {
            width: 100%;
            margin-bottom: 20px;
        }

        &__container {
            flex-direction: column;
        }

        .featured-event {
            width: 100%;
            margin-bottom: 30px;

            &__image-container {
                width: 300px;
            }

            &__taxonomy {
                max-width: 270px;
            }
        }

        &__template-btn {
            width: 100%;
            margin-top: 10px;
        }
    }

    .home-docs-contacts {
        padding-top: 75px;
        padding-bottom: 45px;

        > .container {
            flex-direction: column;
        }
    }

    .home-documents {
        &:before  {
            left: -110px;
            top: -75px;
        }

        .home-document {
            &__title {
                margin: 0 0 40px;
            }

            &__content {
                max-width: 100%;
                flex: 1;
            }
    
            &__template-btn {
                width: 100%;
            }
        }
    }

    .home-annuaire {
        padding-top: 80px;
        width: 100%;

        &__wrapper-select {
            select {
                width: calc(100% - 65px);
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .front-page {
        h2 {
            font-size: 2.8125rem;
            text-align: center;
            align-items: center;
        }
    }
    
    .home-slideshow {
        &:before {
            height: 70px;
            clip-path: ellipse(60% 55px at bottom);
            bottom: -20px;
        }

        &__pagination {
            bottom: 90px;
        }

        .swiper-container {
            height: 400px;
        }

        .video-container {
            height: 400px;
        }

        .slideshow {
            &__content {
                bottom: 120px;
            }

            &__title {
                font-size: 1.875rem;
                line-height: 2.1875rem;
            }
        }
    }

    .home-access {
        margin-top: -55px;

        &__link {
            padding: 0 10px;
        }
    }

    .home-posts {    
        padding-top: 40px;

        &__shape {
            width: 630px;
            top: 100px;
            left: -165px;
        }

        .featured-post {
            &__image-container {
                width: 300px;
                height: 300px;
            }
        }
    }

    .home-events {
        &__shape {
            width: 630px;
            left: -165px;
            top: 70px;
        }

        &__select-wrapper {
            margin-right: 0;

            select {
                width: 218px;
            }

            .select-box:first-child {
                margin-right: 20px;
            }

            label {
                margin: 0 7px 0 0;
            }
        }

        .featured-event {
            flex-direction: column;

            &__image-container {
                border-radius: $border-radius $border-radius 0 0;
            }

            &__content {
                border-radius: 0 0 $border-radius $border-radius;
                min-height: 250px;

                &:before {
                    border-radius: 0 0 $border-radius $border-radius;
                }
            }
        }
    }

    .home-docs-contacts {
        padding-top: 85px;
        padding-bottom: 50px;
    }

    .home-documents {
        text-align: center;

        &:before {
            top: -85px;
            left: 50%;
            transform: translateX(-50%);
        }

        &__container {
            flex-direction: column;
            margin-top: 35px;
        }

        .home-document {
            &__container-image {
                margin: 0 auto 20px;
            }

            &__buttons {
                justify-content: center;
            }

            &__btn {
                margin: 15px 15px 30px;
            }
        }
    }

    .home-annuaire {
        padding-top: 65px;
        text-align: center;

        label {
            margin: 35px 0 8px;
        }

        &__wrapper-select {
            select {
                padding: 0 5px;
                background-position: calc(100% - 5px) 50%;
            }
        }
    }
}
