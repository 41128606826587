.header {
    &__container {
        position: fixed;
        z-index: 99;
        width: 100%;
        height: 80px;
        background-color: $color-white;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);

        > .container {
            position: relative;
        }
    }

    &__identity {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: calc((100% - 1170px) / 2);
        top: 110px;
        z-index: 2;

        &:after {
            content: '';
            position: absolute;
            width: 385px;
            height: 385px;
            left: -85px;
            top: -185px;
            background-color: $color-white;
            border-radius: 50%;
            z-index: -1;
        }
        &__title {
            font-size: 0;
            line-height: 0;
        }
    }
}



//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}

.tools {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 12;

    &__tool {
        width: 42px;
        height: 42px;
        padding: 0;
        margin: 0;

        &:hover, &:focus {
            background-color: transparent;
        }
    }
    
    &__accessibility {
        background-color: $color-second;
        border-color: $color-second;

        svg {
            fill: $color-white;
            width: 42px;
            height: 42px;
        }

        &:hover, &:focus {
            border-color: $color-white;

            svg {
                fill: $color-white;
            }
        }
    }

    &__search {
        background-color: $color-white;
        border-color: $color-white;
        margin-left: 20px;

        svg {
            fill: $color-main;
            width: 42px;
            height: 42px;
        }

        &--disabled {
            pointer-events: none;
            opacity: .2;
        }

        &:hover, &:focus {
            border-color: $color-white;

            svg {
                fill: $color-white;
            }
        }
    }
}

.tools-view {
    position: absolute;
    top: 165px;
    width: 100%;
    z-index: 1;

    .container--tools-view {
        display: flex;
        justify-content: flex-end;

        #google_translate_element {
            display: none;
        }

        .profils {
            position: relative;
            display: none;
            width: 240px;
            height: auto;
            margin-top: 45px;
            margin-right: 60px;
            padding: 15px 20px 20px;
            background: $color-second;

            &.show-list {
                display: flex;
                flex-direction: column;
            }

            &__title {
                color: $color-white;
                text-transform: uppercase;
                margin: 20px 0 15px;
            }

            &__list {
                li {
                    a {
                        position: relative;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        padding: 10px 20px 10px 0px;
                        border-top: 1px solid $color-white;
                        color: $color-white;
                        transition: all $duration;

                        &:hover, &:focus {
                            padding-left: 10px;
                        }

                        &::after {
                            content: "\f344";
                            font: normal 13px/1 dashicons;
                            display: flex;
                            align-items: center;
                            position: absolute;
                            right: 0;
                            height: 100%;
                            color: $color-white;
                        }
                    }

                    &:last-child {
                        a {
                            border-bottom: 1px solid $color-white;
                        }
                    }
                }
            }
        }
    }
}

iframe.goog-te-menu-frame.skiptranslate {
    position: fixed;
    top: 230px !important;
    left: calc(100% - ((100% - 1200px) / 2) - 335px) !important;
}


//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover, &:focus {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(100% - (100% - 960px)/2 - 335px)!important;
        top: 230px!important;
    }

    .header {
        &__identity {
            left: calc((100% - 930px) / 2);

            &:after {
                width: 335px;
                height: 335px;
                left: -60px;
                top: -165px;
            }

            &__title {
                svg {
                    width: 220px;
                    height: auto;
                }
            }
        }
    }

    .tools {
        right: 10px;

        &__tool {
            width: 35px;
            height: 35px;

            svg {
                width: 35px;
                height: 35px;
            }
        }

        &__search {
            margin-left: 12px;
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(100% - (100% - 640px)/2 - 335px)!important;
        top: 240px!important;
    }

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }

    .header {
        &__container {
            position: relative
        }
        
        &__identity {
            top: 100px;
            left: calc((100% - 620px) / 2);

            &:after {
                width: 280px;
                height: 280px;
                left: -50px;
                top: -135px;
            }

            &__title {
                svg {
                    width: 180px;
                }
            }
        }
    }

    .tools {
        height: 80px;

        &:after {
            content: '';
            position: absolute;
            right: -500px;
            width: 500px;
            height: 100%;
            background-color: $color-main;
        }

        &:before {
            content: '';
            position: absolute;
            background-color: $color-main;
            clip-path: ellipse(100% 135% at 105% 50%);
            height: 80px;
            width: calc(100% + 60px);
            left: -60px;
            z-index: -1;
        }
    }
  
}


// 640
@media screen and (max-width: $small) {

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute!important;
        left: calc(100% - (100% - 300px/2))!important;
        top: 280px!important;
    }

    .header {
        &__identity {
            left: 50%;
            transform: translateX(-50%);

            &:after {
                width: 230px;
                height: 230px;
                top: -110px;
            }

            &__title {
                svg {
                    width: 150px;
                }
            }
        }
    }

    .tools {
        &:before {
            left: -30px;
        }
    }

}
