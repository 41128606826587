.cover {
    position: relative;

    &__image-wrapper {
        width: 100%;
        height: 600px;
        background: $color-bg--image;
        position: relative;
    
        &:before {
            content: "";
            position: absolute;
            margin: auto;
            width: 100%;
            height: 150px;
            background: $color-white;
            clip-path:ellipse(55% 110px at bottom);
            z-index: 3;
            bottom: -40px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        position: relative;
        background: $color-white;
        text-align: center;
        padding-bottom: 20px;
        z-index: 5;

        &:after {
            content: '';
            display: inline-block;
            max-width: 370px;
            height: 6px;
            width: 100%;
            margin: auto;
            background-color: $color-main;
            border-radius: $border-radius;
            opacity: .5;
        }
    }

    &__title {
        color: $color-main;
        margin: 0 0 15px;
    }

    &__intro {
        color: $color-text;
        font-weight: $font-weight-extrabold;
        font-size: $font-size--5;
        line-height: 1.375rem;
        margin: 0 auto 40px;
        max-width: 980px;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .cover {
        &__image-wrapper {
            &:before {
                height: 80px;
                clip-path: ellipse(60% 80px at bottom);
                bottom: -30px;
            }
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .cover {
        &__image-wrapper {
            height: 380px;
        }
    }

}


// 640
@media screen and (max-width: $small) {
    .cover {
        &__image-wrapper {
            height: 255px;

            &:before {
                height: 70px;
                clip-path: ellipse(60% 55px at bottom);
                bottom: -20px;
            }
        }

        &__informations {
            &:after {
                width: 180px;
            }
        }
    }

}
