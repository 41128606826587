.footer {
    padding-top: 30px;

    &-shape {
        width: 100%;
        height: 70px;
    }

    &-top {
        position: relative;
        background-color: $color-main;

        &:before {
            content: "";
            position: absolute;
            margin: auto;
            width: 100%;
            height: 150px;
            background: $color-white;
            clip-path:ellipse(55% 110px at top);
            z-index: 0;
            top: -40px;
        }
    }

    &-logos {
        position: relative;
        display: flex;
        justify-content: flex-end;

        &__link {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $color-white;
            box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
            border-radius: 20px;
            overflow: hidden;
            margin: -10px 0 0 40px;
            width: 250px;
            height: 110px;

            &:hover, &:focus {
                box-shadow: 0px 0px 10px rgb(0 0 0 / 40%);
            }

            img {
                width: 100%;
            }
        }
    }

    &-infos {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 25px;
        color: $color-white;

        &:before {
            content: '';
            position: absolute;
            width: 590px;
            height: 590px;
            border-radius: 50%;
            background-color: $color-second;
            top: 20px;
            left: -375px;
        }

        &__logo {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 240px;
            height: 240px;
            border-radius: 50%;
            background-color: $color-white;
            box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
            margin-bottom: 30px;
            flex: none;

            svg {
                width: 195px;
                height: auto;
            }
        }

        &__address {
            flex: none;
        }

        &__title {
            font-family: $font-family--heading;
            font-size: 1.500rem;
            font-weight: $font-weight-bold;
            margin: 30px 0 10px;
        }

        &__text {
            font-size: 1.125rem;
            line-height: 1.375rem;
        }

        &__phone {
            display: flex;
            font-size: 1.125rem;
            font-weight: $font-weight-extrabold;
            margin: 25px 0;

            span {
                transition: $duration;
                border-bottom: 2px solid transparent;
            }

            &:hover, &:focus {
                color: $color-white;

                span {
                    border-color: $color-white;
                }
            }
        }

        &__hours {
            display: flex;
            flex-direction: column;
            margin-top: 30px;
            width: 280px;
        }

        &__contact-link {
            margin: 0;
            background-color: $color-white;
            border-color: $color-white;
            color: $color-dark;
            margin-bottom: 30px;

            svg {
                fill: $color-main;
                margin: 0 15px 0 0;
            }

            &:hover, &:focus {
                background-color: $color-main;
                border-color: $color-white;
                color: $color-white;

                svg {
                    fill: $color-white;
                }
            }
        }

        &__social {
            display: flex;
            flex-direction: column;
        }

        &__social-btn {
            position: relative;
            display: inline-flex;
            align-items: center;
            font-weight: $font-weight-extrabold;
            font-size: $font-size--text-xsmall;
            text-transform: uppercase;
            color: $color-white;
            padding-right: 25px;
            margin: 30px 0 0;
            width: 250px;

            svg {
                position: relative;
                margin-right: 10px;
                width: 52px;
                height: 52px;
                background-color: $color-white;
                border-radius: 50%;
            }

            span {
                position: relative;
            }

            &:before {
                content: '';
                position: absolute;
                left: 0;
                width: 52px;
                height: 52px;
                background-color: $color-white;
                border-radius: 30px;
                transition: $duration;
                z-index: 0;
            }

            &:hover, &:focus {
                color: $color-dark;

                &:before {
                    width: 100%;
                }
            }
        }
    }

    .footer-menu {
        display: flex;
        justify-content: flex-end;
        padding: 25px 0;

        &__item {
            border-right: 1px solid $color-gray;
            padding: 0 20px;

            &:last-child {
                border-right: none;
                padding: 0 0 0 20px;
            }
        }

        &__link {
            color: $color-gray;
            line-height: 25px;

            &:hover, &:focus {
                color: $color-dark;
                text-decoration: underline;
            }
        }
    }
}

body:not(.home) {
    .footer {
        padding-top: 0;

        &-top {
            padding-top: 90px;
        }

        &-logos {
            display: none;
        }
    }
}

//======================================================================================================
// Responsive 
//======================================================================================================
// 1200
@media screen and (max-width: $large) {
    .footer {
        padding-top: 10px;

        &-top {
            &:before {
                height: 80px;
                clip-path: ellipse(60% 80px at top);
                top: -30px;
            }
        }
    
        &-logos {
            &__link {
                margin: -10px 0 0 35px;
                width: 190px;
                height: 85px;
            }
        }
    
        &-infos {
            margin-top: 50px;
    
            &:before {
                width: 410px;
                height: 410px;
                top: 45px;
                left: -300px;
            }
    
            &__logo {
                width: 185px;
                height: 185px;
                margin-bottom: 60px;
    
                svg {
                    width: 145px;
                }
            }

            &__hours {
                margin-top: 10px;
            }
    
            &__title {
                margin: 10px 0;
            }

            &__contact-link {
                margin-bottom: 20px;
            }
    
            &__social-btn {
                width: fit-content;
                margin: 10px 0 0;
            }
        }
    
        .footer-menu { 
            &__item {
                padding: 0 12px;
    
                &:last-child {
                    padding: 0 0 0 12px;
                }
            }
        }
    }

    body.home {
        .footer {
            padding-top: 30px;
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .footer {
        padding-top: 0;

        &-top {
            padding-bottom: 60px;
        }
    
        &-logos {
            &__link {
                margin: 0 0 0 20px;
                width: 140px;
                height: 62px;
            }
        }
    
        &-infos {
            flex-wrap: wrap;
            margin-top: 10px;
    
            &:before {
                display: none;
            }
    
            &__logo {
                width: 150px;
                height: 150px;
                margin-bottom: 30px;
                margin-right: 200px;
    
                svg {
                    width: 120px;
                }
            }
    
            &__address {
                margin-right: 0;
                width: 50%;
            }
    
            &__hours {
                width: 50%;
            }
    
            &__social-btn {
                margin: 20px 0 0;
            }
        }
    
        .footer-menu {
            padding: 35px 0 5px;
            justify-content: flex-start;
            flex-wrap: wrap;
    
            &__item {
                padding: 0 30px 0 0;
                margin-bottom: 25px;
                margin-right: 30px;
    
                &:last-child {
                    padding: 0;
                }
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .footer {
        padding-top: 25px;

        &-top {
            &:before {
                height: 70px;
                clip-path: ellipse(60% 55px at top);
                top: -20px;
            }
        }
    
        &-logos {
            justify-content: center;

            &__link {
                margin: -15px 10px 0;
            }
        }
    
        &-infos {
            margin-top: 50px;
            flex-direction: column;
            justify-content: center;
    
            &__logo {
                width: 150px;
                height: 150px;
                margin: 0 auto 30px;
            }
    
            &__address {
                text-align: center;
                width: 100%;
            }

            &__phone {
                margin: 25px auto;
                justify-content: center;
            }

            &__contact-link {
                margin-bottom: 45px;
            }
    
            &__hours {
                text-align: center;
                width: 100%;
            }
    
            &__social-btn {
                margin: 30px auto 0 0;
                width: auto;
            }
        }
    
        .footer-menu {
            padding: 25px 0 0px;
    
            &__item {
                padding: 0 25px 0 0;
                border-right: none;
                margin: 0 0 25px 0;
            }
        }
    }

    
    body:not(.home) {
        .footer-infos {
            margin-top: 0;
        }
    }
}
