//==============================================================================
// Abstracts
//==============================================================================
@import "abstracts/variables";
@import "abstracts/mixins";
@import "abstracts/placeholders";


//==============================================================================
// Base
//==============================================================================
@import "base/font-face";
@import "base/helpers";
@import "base/normalize";
@import "base/print";
@import "base/reset";
@import "base/scaffolding";


//==============================================================================
// Components
//==============================================================================
@import "components/buttons";
@import "components/cards";
@import "components/datepicker";
@import "components/menu";
@import "components/pagination";
@import "components/flexibles";
@import "components/see-more";
@import "components/breadcrumb";
@import "components/actions-post";
@import "components/post-navigation";
@import "components/accordion";
@import "components/proposition";
@import "components/document";
@import "components/search";
@import "components/scroll-top";
@import "components/table";
@import "components/tabs";
@import "components/countdown";


//==============================================================================
// Layout
//==============================================================================
@import "layout/filter";
@import "layout/footer";
@import "layout/forms";
@import "layout/grid";
@import "layout/header";
@import "layout/layout";
@import "layout/listing-cards";
@import "layout/tables";
@import "layout/top-publications";
@import "layout/listing";
@import "layout/404";


//==============================================================================
// Pages
//==============================================================================
@import "pages/home";
@import "pages/single";
@import "pages/documents";
@import "pages/transition";
@import "pages/maps";
@import "pages/payfip";


//==============================================================================
// Plugins
//==============================================================================
@import "plugins/events/v2";

@import "plugins/contacts/base";
@import "plugins/contacts/v1/contact-details.scss";
@import "plugins/contacts/v2/contact-details.scss";
@import "plugins/contacts/v3/contact-article.scss";

@import "plugins/comarquage/co-variables";
@import "plugins/comarquage/co-icons";
@import "plugins/comarquage/co-base";
@import "plugins/comarquage/co-style";
@import "plugins/comarquage/co-header";
@import "plugins/comarquage/co-archive";
@import "plugins/comarquage/co-single";
@import "plugins/comarquage/co-flexibles";

@import "plugins/infowindow/infowindow";

@import "plugins/newsletters/newsletters-form";

@import "plugins/accessconfig/accessconfig.scss";

// Documents
@import "plugins/documents/documents-button";
@import "plugins/documents/documents-details";
@import "plugins/documents/documents-card";
@import "plugins/documents/documents-category";

//==============================================================================
// Themes
//==============================================================================

@import "rgaa.scss";

//==============================================================================
// Vendors
//==============================================================================
@import '../../node_modules/swiper/css/swiper.css';
@import '../../node_modules/glightbox/dist/css/glightbox.css';
