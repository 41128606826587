.breadcrumb {
    ul  {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 20px;
    }

    li {
        display: flex;
        color: $color-gray;
        font-weight: $font-weight-medium;

        > svg {
            fill: $color-gray;
            width: 20px;
            height: 20px;
            margin: 0 12px;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        position: relative;

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 40px;
            width: 100%;
            height: 1px;
        }

        ul {
            min-height: 40px;
        }

        li {
            min-height: 40px;
        } 
    }

}
