#nav-main {
    position: relative;
    z-index: 10;

    .nav-main-home {
        display: flex;
        align-items: center;
        height: 42px;
        width: 42px;
        border-radius: 50%;
        background-color: $color-main;
        padding: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;

        svg {
            fill: $color-white;
            height: 42px;
            width: 42px;
        }

        &:after {
            display: none;
        }

        &:hover {
            background-color: $color-second;
        }
    }

    .menu__nav__list {
        display: flex;
        flex-wrap: wrap;
        padding-left: 45px;
        transition: $duration ease-out;
    } 

    .nav-main-item {

        &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
            .sub-menu {
                display: flex;
                justify-content: center;
            }
        }

        &:last-child {
           .nav-main-link {
               background-color: $color-main;
               color: $color-white;
               clip-path: ellipse(100% 150% at 100% 50%);

               &:after {
                   display: none;
               }
            }

            &:after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 2000px;
                height: 100%;
                background: $color-main;
                right: -1880px;
                z-index: -1;
            }
        }

        &:nth-last-child(2){
            .nav-main-link {
                &:after {
                    display: none;
                }
            }
        }

        .nav-main-link {
            position: relative;
            display: flex;
            align-items: center;
            height: 80px;
            padding: 0 18px;
            color: $color-dark;
            text-transform: uppercase;
            font-size: 1.375rem;
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            z-index: 1;

            &:before,
            &:after {
                content: '';
                position: absolute;
            }

            &:before {
                bottom: -4px;
                width: 0;
                height: 0;
                border-radius: 10px;
                background: $color-second;
                left: 0;
                right: 0;
                margin: auto;
                z-index: 1;
                transition: .3s;
            }

            &:after {
                top: 50%;
                right: 0;
                width: 4px;
                height: 18px;
                border-radius: 10px;
                background: $color-second;
                transform: translateY(-50%);
            }
        }

        &:hover {
            .nav-main-link {
                &:before {
                    height: 8px;
                    width: calc(100% - 40px);
                }
            }
        }

        .sub-menu {
            display: none;
            position: absolute;
            left: 0;
            width: 100%;
            padding: 30px 0;
            background: $color-third;
            box-shadow: $shadow;
            
            &:after, 
            &:before {
                content: "";
                width: 2000px;
                height: 100%;
                top: 0;
                position: absolute;
                background: $color-third;
            }

            &:before {
                left: -2000px;
                
            }

            &:after {
                right: -2000px;
                
            }

            ul {
                width: 100%;
                column-count: 3;
                column-gap: 30px;
            }

            .nav-child-item, .nav-grandchild-item {
                display: inline-flex;
                width: 100%;
                margin: -1px; // Fix space beetween inline-flex
                padding: 0;
                line-height: initial;

                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 60px;
                    padding: 15px 30px 15px 0;
                    margin-bottom: 1px; // Fix space beetween inline-flex
                    border-bottom: 1px solid $color-dark--rgba;
                    color: $color-text;
                    font-weight: $font-weight-extrabold;

                    &::before {
                        content: "";
                        display: inherit;
                        position: absolute;
                        bottom: -1px;
                        left: 0;
                        width: 0;
                        height: 2px;
                        background: $color-main;
                        transition: all $duration;
                    }

                    &::after {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        position: absolute;
                        right: 0;
                        color: $color-dark--rgba;
                        transition: all $duration;
                    }

                    &:hover {
                        color: $color-main;
                        padding: 15px 30px 15px 15px;

                        &::before {
                            width: 100%;
                        }
                        &::after {
                            color: $color-main;
                        }
                    }
                }
            }

            .nav-grandchild-item {
                a {
                    display: flex;
                    padding: 17px 10px 17px 15px;
                    border-bottom: 0;
                    font-size: $font-size--text-small;
                    font-weight: $font-weight-medium;
                    text-transform: initial;
                    line-height: initial;
                    border-color: transparent;

                    &::before {
                        display: none;
                    }
                } 
            }
        }
    }
}

// Responsive button
.nav-main_button {
    display: none;
}

// Responsive button label
.nav-main_icon {
    display: none;
    align-items: center;
    cursor: pointer;
    padding: 10px 20px;
    color: $color-white;

    .navicon {
        position: relative;
        display: block;
        height: 3px;
        top: -1px;
        width: 18px;
        margin-right: 18px;
        background: $color-white;
        border-radius: 10px;
        transition: all $duration ease-out;

        &:before, 
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background: $color-white;
            transition: all $duration ease-out;
        }

        &:before {
            top: 7px;
        }

        &:after {
            top: -7px;
        } 
    }
}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    #nav-main {
        .nav-main-home {
            width: 35px;
            height: 35px;

            svg {
                width: 35px;
                height: 35px;
            }
        }

        .menu__nav__list {
            padding-left: 35px;
        }

        .nav-main-item {
            .nav-main-link {
                font-size: 1.125rem;
                padding: 0 14px;
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    #nav-main {
        position: relative;

        .container--nav {
            position: relative;
        }

        .menu__nav__list {
            display: flex;
            flex-flow: column;
            position: absolute;
            top: 80px;
            width: 100%;
            margin: 0;
            padding: 0;
            background: $color-main;
        }

        .nav-main-item {
            .nav-main-link {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                max-width: 100%;
                height: 60px;
                padding: 0 20px;
                border-bottom: 1px solid $color-white;
                color: $color-white;
                text-transform: uppercase;

                &:after {
                    display: none;
                }
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }

            &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
                .sub-menu {
                    display: none;
                }
            }

            &.active,
            &.menu-item-has-children.active {
                background: $color-bg--neutral;
                box-shadow: 0 0 1px rgb(27 35 54 / 8%), 1px 1px 10px rgb(27 35 54 / 8%);

                .nav-main-link {
                    border-bottom: 1px solid $color-gray;
                    color: $color-main;
                }

                .sub-menu {
                    display: flex;
                    position: relative;
                    padding: 0;
                    box-shadow: none;

                    &:before,
                    &:after {
                        display: none;
                    }

                    .nav-drop {
                        column-count: 1;
                        padding: 0;
                    }

                    li:last-child {
                        a {
                            border-bottom: 0;

                            &::before {
                                display: none;
                            }

                            &::after {
                                top: 50%;
                                transform: translate(0,-50%);
                            }
                        }
                    }
                }
            }

            .sub-menu {
                .nav-child-item,
                .nav-grandchild-item {
                    a {
                        height: 45px;
                        padding: 15px 30px;

                        &:hover {
                            padding: 15px 30px;
                        }
                    }
                }
            }
        }

        // Responsive button
        .nav-main_button {
            ~ .menu {
                clear: both;
                transform: scaleY(0);
                transform-origin: 50% 0;
                transition:transform .3s ease;
            }

            &:checked {
                ~ .menu {
                    transform: scaleY(1);
                }
                ~ .nav-main_icon {
                    .navicon {
                        background: $color-bg--transparent;

                        &:before {
                            transform: rotate(-45deg);
                        }

                        &:after {
                            transform: rotate(45deg);
                        }
                    }

                    &:not(.steps) {
                        .navicon {
                            &:before,
                            &:after {
                                top: 0;
                            }
                        }
                    }
                }
            }
        }

        // Responsive button label
        .nav-main_icon {
            display: flex;
            width: 100%;
            height: 80px;
            margin: 0;
            padding: 0;
            font-size: 1.375rem;
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            text-transform: uppercase; 
            color: $color-dark;
            padding-left: 80px;

            &:before {
                content: '';
                position: absolute;
                background-color: $color-second;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                left: 71px;
                z-index: -1;
            }
        }
    }
    
}


// 640
@media screen and (max-width: $small) {
    #nav-main {
        .nav-main_icon {
            padding: 0 0 0 63px;
            
            &:before {
                left: 55px;
            }
        }
    }
}